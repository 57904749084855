.amount-container {
    display: flex;
    flex-flow: column;
    flex: 1;
    margin-top: 26px;
}

.amount-main {
    display: flex;
    flex-flow: column;
}

.amount-main .subtitle {
    text-align: right;
}

.amount-sub {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
}

.amount-sub .item {
    margin: 2px 4px;
    text-align: right;
}

.amount-sub .item.item-note {
    display: flex;
    flex-flow: column;
}

.amount-sub .item .note-text {
    color: red;
}

.amount-sub .item label {
    padding-right: 1px;
}

.amount-main span.amout-value {
    text-align: right;
    font-size: 32px;
    line-height: 44px;
}
.amount-value-container {
    text-align: right;
}
.amount-wrap {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.amount-wrap .note-text {
    text-align: right;
}

.amount-unit {
    margin-left: 2px;
}

.amount-spin.ant-spin.ant-spin-spinning {
    position: absolute;
}

.amount-spin.ant-spin.ant-spin-spinning svg {
    color: #FF6A00;
}
