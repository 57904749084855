
.ant-tabs.customer-page {
    margin: 16px;
}

.customer-tab .ant-select {
    width: 200px;
}


@media screen and (max-width: 576px) {
    .ant-tabs.customer-page {
        margin: 12px;
    }

    .customer-tab .ant-select {
        width: 100px;
    }

    .customer-tab .widget {
        margin: 4px;
    }
}
