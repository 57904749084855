

.Subtitle-Large-S2L-B {
    font-family: IBM Plex Sans Thai;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
}

.rc-tree-title {
    color: #433A31;
    display: flex;
    flex: 1;
    justify-content: space-between;
}

.rc-tree-title > i {
    margin: 0 36px;
}

.rc-tree-title > i.offline {
    margin: 0 8px;
}

.PowerSupply .rc-tree-title {
    font-family: IBM Plex Sans Thai;
    font-size: 17px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
}

.Line .rc-tree-title {
    font-family: IBM Plex Sans Thai;
    font-size: 14px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
}

.rc-tree-treenode {
    height: 40px;
    display: flex;
    align-items: center;
    -webkit-transition: border-radius .2s;
    transition: border-radius .2s;
}

.rc-tree-treenode:hover {
    background: rgba(246, 244, 239, 0.5);
}

.rc-tree-treenode-selected {
    background: #D1CABC;
}

.rc-tree-node-selected {
    background-color: unset;
    box-shadow: unset;
    opacity: unset;
}

.Line.rc-tree-treenode {
    background-color: #FF8833;
    border-radius: 20px;
    margin: 6px 0;
}

.Line.rc-tree-treenode.rc-tree-treenode-switcher-open {
    border-radius: 20px 20px 0 0;
    margin: 6px 0 0 0;
}

.Line.rc-tree-treenode-selected {
    background-color: #FF6A00;
}

.StationArea.rc-tree-treenode {
    background-color: #FFEBCC;
}

.StationArea.rc-tree-treenode .ant-tag.ant-tag-has-color {
    background-color: #FFC36670;
    margin-right: 16px;
    border-radius: 8px;
}

.StationArea.rc-tree-treenode:hover {
    background: rgba(255, 195, 102, 0.5);
}

.StationArea.rc-tree-treenode-selected {
    background: #FFC366;
}

.StationArea.rc-tree-treenode.rc-tree-treenode-switcher-close.rc-tree-treenode-leaf-last {
    border-radius: 0 0 20px 20px;
}

.StationArea .rc-tree-title {
    font-family: IBM Plex Sans Thai;
    font-style: normal;
    font-size: 14px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
}

.DCU.rc-tree-treenode, .Meter.rc-tree-treenode, .User.rc-tree-treenode{
    height: 48px;
    border-left: 0.5px solid #F6F4EF;
    border-right: 0.5px solid #F6F4EF;
}

.DCU.rc-tree-treenode.rc-tree-treenode-leaf-last.rc-tree-treenode-switcher-close {
    height: 48px;
    border-bottom: 0.5px solid #F6F4EF;
    border-radius: 0 0 20px 20px;
}

.DCU .rc-tree-title, .Meter .rc-tree-title, .User .rc-tree-title {
    font-family: IBM Plex Sans Thai;
    font-style: normal;
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
}

.rc-tree .rc-tree-treenode span.rc-tree-switcher {
    background-image: initial;
}

.rc-tree-switcher.rc-tree-switcher_open svg {
    transform: rotate(180deg);
}

.rc-tree .rc-tree-treenode .rc-tree-node-content-wrapper {
    display: flex;
    align-items: center;
    flex: 1;
}

.rc-tree {
    height: calc(100% - 122px);
    /* overflow: scroll; */
    overflow: hidden;
}
.rc-tree:hover {
    overflow-y: auto;
}

/* .rc-tree::-webkit-scrollbar {
    display: none;
} */

.rc-tree-treenode-loading .rc-tree-title {
    animation: data-loading 1s infinite;
}

.ant-layout-sider-children {
    text-align: center;
}

@keyframes data-loading {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}

::placeholder {
    font-family: 'IBM Plex Sans Thai';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 18px;

    display: flex;
    align-items: center;

    color: #433A31;
    opacity: 0.2;
}

.my-input {
    margin: 10px 0;
}
.my-input > i {
    position: absolute;
    padding-left: 16px;
    padding-top: 12px;
}

.my-input > input {
    padding: 0 40px;
    width: 100%;
    height: 40px;
    border-radius: 200px;
    border-width: 0;
    background-color: #F6F4EF;
}

.my-input > .ant-spin {
    position: absolute;
    right: 22px;
    padding-top: 10px;
}

.my-input > .ant-spin svg {
    fill: #FF6A00;
}

.my-input > .btn-reload {
    position: absolute;
    right: 22px;
    top: 92px;
    padding-top: 40px;
}
.my-input > .btn-reload svg {
    fill: #847463;
}

.segment {
    display: flex;
    justify-content: space-between;
    margin: 21px 0;
}

.segment button {
    display: flex;
    align-items: center;
    background: #F6F4EF;
    border-radius: 20px;
    height: 20px;
    justify-content: center;
    padding-left: 12px;
    padding-right: 12px;
    flex: 1;
    margin: 0 4px;
}

.segment button.selected {
    background: #FF6A00;
}

.segment button:hover {
    border: 0.5px solid #433A31;
}

.segment button span {
    font-family: IBM Plex Sans Thai;
    font-size: 11px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #847463;
}

.segment button.selected span {
    color: #FFFFFF;
}

.caption {
    font-family: IBM Plex Sans Thai;
    font-size: 9px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
}

.ant-space.ant-space-vertical.tree-text-container {
    text-align: left;
    padding-left: 4px;
    gap: 0 !important;
}
