.ant-layout-header.nav-header {
    height: 100px;
    padding: 0;
    background: #F6F4EF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    z-index: 3;
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
}

.nav-title {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-title span {
    font-size: 21px;
    line-height: 35px;
    text-align: center;
}

.nav-menu {
    flex: 1;
    display: flex;
    gap: 24px;
}

.nav-menu .btn {
    width: 60px;
    height: 30px;
    border-radius: 50px;
    align-self: center;
}

.btn.btn-action {
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    width: 80px;
}

.btn.btn-action.btn-action-primary {
    color: #FFFFFF;
    background: #FF6A00;
}

.ant-notification-notice.custom-notification-notice {
    width: 320px;
}

.ant-notification-notice .ant-notification-notice-btn {
    margin-top: 8px;
}

.btn .text {
    font-family: 'IBM Plex Sans Thai';
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 15px;
    display: block;
}

.nav-logo {
    align-self: center;
    display: flex;
    justify-content: center;
    flex-flow: column;
    margin: 0 32px;
}

.ant-layout-sider.side-menu {
    padding: 12px;
    background: #FFFFFF;
    box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    position: fixed;
    left: 0;
    top: 100px;
    bottom: 0;
    overflow: auto;
}

.main-layout {
    margin-top: 100px;
}

.site-layout {
    padding: 0;
    margin-left: 300px;
    transition: all 0.2s;
}

.ant-tabs-tab .ant-tabs-tab-btn {
    
}

.ant-tabs-tab.ant-tabs-tab-active div.ant-tabs-tab-btn {
    color: #FF6A00;
}

.tab-container {
    /* display: flex;
    flex-direction: row; */
    min-height: calc(100vh - 230px);
}

.tab-container .widget {
    margin: 10px;
    /* min-height: calc(100vh - 230px); */
}

/* .tab-container .ant-col {
    display: flex;
} */

.version {
    text-align: right;
}

.ant-layout-footer.footer {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: baseline;
    padding: 8px 12px;
    background-color: #F6F4EF;
    margin-left: 300px;
}

.ant-layout-footer.footer.footer-login {
    margin-left: 0;
}

.float-main-menu {
    display: none;
}

.ant-drawer {
    display: none;
}

.clock-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .nav-header .nav-menu .clock {
    padding-left: 24px;
} */

.widget-list .ant-list-item {
    padding: 4px 12px;
}

.ant-tabs.meter-tabs {
    margin: 16px;
}

@media screen and (max-width: 992px) {

    .clock-container {
        display: none;
    }

    .nav-title {
        display: none;
    }

    .nav-menu {
        margin-left: 36px;
    }

}

@media screen and (max-width: 576px) {

    .nav-menu {
        margin-left: 0;
    }

    .nav-title {
        display: flex;
    }

    .nav-title span {
        font-size: 14px;
        line-height: 14px;
    }

    .ant-layout-header.nav-header {
        padding: 24px;
        height: 49px;
    }

    .nav-header .nav-menu .main-menu {
        display: none;
    }

    .nav-header .nav-menu .clock {
        display: none;
    }

    .nav-header .nav-menu .widget-menu {
        display: none;
    }
    .nav-header .nav-menu .edit-widget-menu {
        display: none;
    }
    .nav-menu .btn {
        width: 50px;
        height: 25px;
    }

    .ant-layout-sider.side-menu {
        padding: 0;
    }

    .main-layout {
        margin-top: 49px;
    }

    .site-layout {
        padding: 0;
        margin-left: 0;
    }

    .ant-layout-footer.footer {
        margin-left: 0;
    }

    .nav-logo {
        margin: 0;
        margin-left: 24px;
    }

    .nav-logo svg {
        width: 30px;
        height: 30px;
    }

    .float-main-menu {
        display: block;
        position: absolute;
        left: 24px;
        right: 24px;
        transition: all 0.2s;
    }

    .float-main-menu .ant-affix > div {
        display: flex;
        justify-content: space-between;
        align-items: self-end;
    }

    .float-main-menu .btn {
        width: 44px;
        height: 44px;
        border-radius: 44px;
    }

    .float-main-menu .btn svg {
        width: 20px;
        height: 20px;
        vertical-align: middle;
    }

    .float-main-menu .btn.btn-action {
        width: 100px;
    }

    .ant-drawer .ant-drawer-body {
        padding: 0;
    }

    .ant-drawer-body .ant-layout-header.nav-header {
        width: 300px;
    }

    .ant-drawer-body .nav-header .nav-menu .main-menu {
        display: inline-flex;
    }

    .ant-layout-sider.side-menu {
        top: 44px;
    }

    .ant-drawer-body .ant-layout-sider.side-menu {
        padding: 12px;
    }

    .ant-drawer-body .nav-title {
        display: none;
    }

    .ant-drawer-body .nav-title.forced-show {
        display: inline-flex;
    }

    .ant-drawer {
        display: initial;
    }

    .widget-list .ant-list-item {
        padding: 4px 24px;
    }

    .ant-tabs.meter-tabs {
        margin: 8px;
    }
}
