.widget {
    display: flex;
    flex-flow: column;
    padding: 16px 32px;
    /* padding-top: 16px; */
    background: #FFFFFF;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
}

.widget .header .widget-controls .ant-btn.btn-collapse  {
    display: none;
}

@media screen and (max-width: 576px) {
    .widget {
        padding: 16px 24px;
    }
    .widget .header .widget-controls .ant-btn.btn-collapse  {
        display: inline-block;
    }
}

.widget.readonly {
    border-radius: 16px;
    transition: height .2s ease-in-out;
}

.widget .header {
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 4px 0;
    border-bottom: 0.5px solid #847463;
}

.widget .header .widget-controls .ant-btn {
    width: 30px;
    height: 30px;
}

.widget .header .widget-controls .ant-btn svg {
    transition: rotate .5s ease-out;
}

.widget.collapsed .header .widget-controls .ant-btn.btn-collapse svg {
    rotate: 180deg;
}

.widget .body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    overflow: hidden;
    transition: all 0.2s;
}

.widget.collapsed .body {
    display: none;
}

.layout.readonly  {
    background: #F6F4EF;
}

.layout {
    min-height: 100vh;
    background: #776E64;
}

/* .react-grid-item > .react-resizable-handle.react-resizable-handle-se {
    bottom: 8px;
    right: 8px;
    cursor: se-resize;
} */

/* .react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
}

.react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
} */

.react-grid-item.widget  > .react-resizable-handle {
    display: none;
}


.widget .ant-btn.btn-reload {
    position: absolute;
    z-index: 1;
    border-radius: 50px;
}
