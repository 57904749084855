
.ant-tabs.node-comparison-page {
    margin: 16px;
}

@media screen and (max-width: 576px) {
    .ant-tabs.node-comparison-page {
        margin: 8px;
    }

}