@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: IBM Plex Sans Thai;
}

/* Text */
.head {
  font-family: 'IBM Plex Sans Thai';
  font-style: normal;
  font-weight: 700;
}

.head.head-large {
  font-size: 21px;
  line-height: 35px;
};

.head.head-small {
  font-size: 14px;
  line-height: 14px;
}

.subtitle {
  font-family: 'IBM Plex Sans Thai';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 18px;
}

.subtitle.subtitle--bigger-2 {
  font-size: 17px;
  line-height: 28px;
}

.subtitle.subtitle--bigger-1 {
  font-size: 14px;
  line-height: 23px;
}

.subtitle.subtitle--smaller-1 {
  font-size: 9px;
  line-height: 15px;
}

.subtitle.subtitle--smaller-2 {
  font-size: 7px;
  line-height: 12px;
}

/* Charts */
.chart .chart-text-large {
  font-family: 'IBM Plex Sans Thai Looped';
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 15px;
  color: #433A31;
}

.chart .chart-text-large.chart-text-large--2 {
  font-weight: 400;
  font-size: 9px;
  line-height: 15px;
}

.chart .chart-text-large.chart-text-large--3 {
  font-weight: 400;
  font-size: 7px;
  line-height: 12px;
}

.chart .chart-text-medium {
  font-family: 'IBM Plex Sans Thai Looped';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 18px;
  color: #433A31;
}

.chart .chart-text-medium.chart-text-medium--2 {
  font-weight: 400;
}

.chart .chart-text-medium.chart-text-medium--3 {
  font-size: 9px;
  line-height: 15px;
}

.chart .chart-text-medium.chart-text-medium--4 {
  font-weight: 400;
  font-size: 9px;
  line-height: 15px;
}

.chart .chart-text-small {
  font-family: 'IBM Plex Sans Thai Looped';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 18px;
  color: #433A31;
}

.chart .chart-text-small.chart-text-small--2 {
  font-weight: 400;
}

.chart .chart-text-small.chart-text-small--3 {
  font-size: 7px;
  line-height: 12px;
}

.chart .chart-text-small.chart-text-small--4 {
  font-weight: 400;
  font-size: 7px;
  line-height: 12px;
}

.chart .chart-text-small.chart-text-small--5 {
  font-weight: 400;
  font-size: 5px;
  line-height: 8px;
}

.chart .ant-table-tbody > tr > td, .chart .ant-table-thead > tr > th {
  padding: 10px;
}

.chart .ant-table-thead > tr > th  {
  background-color: #F6F4EF;
}

.chart .ant-table-tbody > tr > td {
  border-bottom: solid 0.5px #E9E3D8;
}

.chart .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.chart .ant-table-pagination .ant-pagination-item {
  font-family: IBM Plex Sans Thai Looped;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0em;
}

.chart .ant-pagination-item-active {
  border-color: #3D3D3D;
}

.chart .ant-pagination-item:hover, .chart .ant-pagination-item-link:hover {
  border-color: #776E64;
}
.chart .ant-pagination-item a:hover {
  color: #776E64;
}

.chart .ant-pagination-item-active a {
  color: #3D3D3D;
}


.btn.btn--selected {
  background-color: #FF6A00;
}

.btn svg {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 576px) {
  .btn svg {
    width: 16px;
    height: 16px;
  }
}

.btn.btn--selected svg > path {
  fill: #FFFFFF;
}

.ant-dropdown-menu-title-content {
  font-family: IBM Plex Sans Thai;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  
}



.login-box-container {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}

.login-container {
  height: calc(100vh - 38px);
  background-color: #F6F4EF;
  margin: 0;
}

.marker-content-wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.marker-content-wrapper svg {
  transform: translate(0, -21px);
}

.event-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.event-box .content {
  display: flex;
  flex-flow: column;
}

.event-box span {
  margin-left: 8px;
}

.ant-btn:hover {
  border: 1px solid #FFA666;
}

.ant-descriptions-title {
  font-family: 'IBM Plex Sans Thai';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 18px;
}

.ant-descriptions-item-label {
  font-family: 'IBM Plex Sans Thai Looped';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 18px;
  color: #433A31;
}

