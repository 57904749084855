.marker.marker-meter {
    display: flex;
    flex-flow: column;
    align-items: center;
    transform: translate(-50%, -50%);
  }
  
.marker.marker-meter span {
color: #FF4200
}

.map-container {
height: calc(100vh - 138px);
width: 100%;
}

.cluster-marker {
    background: #FF4200;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.marker.marker-meter .cluster-marker span {
    color: #fff;
}

.cluster-marker-radar {
    background: #FF4200;
    border-radius: 50%;
    opacity: 0.3;
    position: absolute;
    z-index: -1;
}


@media screen and (max-width: 576px) {

.map-container {
    height: calc(100vh - 87px);
}

.gm-bundled-control {
    display: none;
}

.gm-fullscreen-control {
    display: none;
}

}
