
.notification-list-container {
    position: fixed;
    right: 0;
    top: 100px;
    width: 300px;
    height: 100%;
    padding: 8px 24px;
    background: white;
    z-index: 10;
    border: 0.5px solid rgba(246, 244, 239, 1)
}

.notification-list-container .ant-list-item-meta-description {
    line-height: 14.85px;
}

.notification-list-container .ant-list-item-meta-title {
    line-height: 18.15px;
}

.notification-list {
    height: calc(100% - 122px);
    overflow: auto;
}

.notification-list::-webkit-scrollbar {
    display: none;
}

.caption {
    font-family: 'IBM Plex Sans Thai';
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 15px;
    color: rgba(67, 58, 49, 1);
}

.meter-event.critical .ant-list-item-meta-avatar svg > path {
    fill: red;
}

.meter-event.warning .ant-list-item-meta-avatar svg > path {
    fill: #FFC366;
}

.notification-list-container .header-container {
    display: flex;
    flex-flow: column;
}

.notification-list-container .header-container .header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: baseline;
}

.ant-btn.btn-reload {
    font-family: IBM Plex Sans Thai;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    border-radius: 50px;
}


@media screen and (max-width: 576px) {
    .notification-list-container {
        top: 50px;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
}
